import { useCallback, useContext, useEffect, useState } from 'react';
import { capitalize } from 'lodash';

import notify from 'notify';
import { getUsers } from 'api/users';
import { mapUsers } from 'views/CalendarPage/mapping';
import { tryGetFirstError } from 'utils/requests';
import { DropdownOption } from 'components';
import { CalendarItem } from 'views/CalendarPage/calendar.d';
import {
  completeTask as completeTaskRequest,
  deleteEvent,
  deleteTask
} from 'views/CalendarPage/api';
import WorkspaceContext from '../WorkspaceContext';
import {
  deleteNote as deleteNoteRequest,
  addNote,
  getNotes,
  getWorksapceCalendarItems,
  getWorksapceDeadlinesXP
} from '../api';

export default () => {
  const { listingId } = useContext(WorkspaceContext);
  const [tasks, setTasks] = useState<CalendarItem[]>();
  const [deadlines, setDeadlines] = useState<WorkspaceDeadline[]>();
  const [notes, setNotes] = useState<WorkspaceNote[]>();
  const [calendarEdit, setCalendarEdit] = useState<Partial<CalendarItem>>();
  const [calendarDelete, setCalendarDelete] = useState<CalendarItem>();
  const [documentsOptions, setDocumentsOptions] = useState<DropdownOption[]>([]);
  const [usersOptions, setUsersOptions] = useState<DropdownOption[]>([]);
  const [isAddingNote, setIsAddingNote] = useState(false);
  const [addNoteText, setAddNoteText] = useState('');
  const [addOrderModalData, setAddOrderModalData] = useState<CalendarItem>();

  const cancelAddNote = () => {
    setIsAddingNote(false);
    setAddNoteText('');
  };

  const removeNote = async (noteId, callback) => {
    try {
      await deleteNoteRequest(noteId);
      setNotes(notes => notes?.filter(item => item.id !== noteId));
      notify('Note deleted successfully');
      if (callback) callback();
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const submitNewNote = async e => {
    if (addNoteText) e.preventDefault();
    try {
      const notes = await addNote({ listingId, text: addNoteText });
      setNotes(notes);
      cancelAddNote();
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const completeTask = async event => {
    try {
      await completeTaskRequest(event);
      fetchCalendarItems();
      notify('Task was successfully updated');
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const deleteCalendarItem = async event => {
    try {
      const deleteRequest = event?.dateType === 'task' ? deleteTask : deleteEvent;
      await deleteRequest(event);
      fetchCalendarItems();
      notify(`${capitalize(calendarDelete?.dateType)} successfully deleted`);
      setCalendarDelete(undefined);
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const fetchNotes = useCallback(async () => {
    try {
      setNotes(await getNotes(listingId));
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  }, [listingId]);

  const fetchCalendarItems = useCallback(async () => {
    let newDeadlines: WorkspaceDeadline[] = [];
    const getCalendarItems = async () => {
      try {
        const { tasks, deadlines, documents } = await getWorksapceCalendarItems(listingId);
        setTasks(tasks);
        newDeadlines = [...newDeadlines, ...deadlines];
        // setDeadlines(deadlines);
        setDocumentsOptions(documents);
      } catch (err) {
        notify(tryGetFirstError(err));
      }
    };

    const getDeadlinesXP = async () => {
      try {
        const deadlines = await getWorksapceDeadlinesXP(listingId);
        newDeadlines = [...newDeadlines, ...deadlines];
      } catch (err) {
        notify(tryGetFirstError(err));
      }
    };

    await Promise.all([getCalendarItems(), getDeadlinesXP()]);

    setDeadlines(newDeadlines.sort((a, b) => a.date - b.date));
  }, [listingId]);

  useEffect(() => {
    (async () => {
      try {
        const users = await getUsers();
        const mappedData = mapUsers(users);
        setUsersOptions(mappedData);
      } catch (err) {
        notify(err.message);
      }
    })();
  }, []);

  useEffect(() => {
    if (!listingId) return;
    fetchNotes();
    fetchCalendarItems();
  }, [fetchNotes, fetchCalendarItems, listingId]);

  return {
    listingId,
    tasks,
    deadlines,
    notes,
    calendarEdit,
    setCalendarEdit,
    calendarDelete,
    setCalendarDelete,
    documentsOptions,
    usersOptions,
    isAddingNote,
    setIsAddingNote,
    addNoteText,
    setAddNoteText,
    addOrderModalData,
    setAddOrderModalData,
    removeNote,
    submitNewNote,
    cancelAddNote,
    completeTask,
    fetchCalendarItems,
    deleteCalendarItem
  };
};
