/* eslint-disable react/no-danger */
import React, { useState, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import notify from 'notify';

import { getListingAddress, getListingImageProps } from 'utils/listing';
import { tryGetFirstError } from 'utils/requests';
import { Button, Input, Dropdown, InputAutocomplete, Icon, Image } from 'components';
import { RadioButton } from '@xchange/uikit';
import { AutocompleteOption } from 'components/InputAutocomplete';
import { REGEXP, STATES } from 'consts';
import { searchListingsByAddressOrMlsId } from './api';
import BuyProcessHint from './BuyProcessHint';
import { ReactComponent as HouseCheckmarkSVG } from './houseCheckmark.svg';

const STEP = 1;

const statesOptions = Object.entries(STATES).map(([value, text]) => ({ value, text }));

const MlsSearchItem = ({ title, text, term }) => {
  const html = title.replace(term, `<strong>${term}</strong>`);
  return (
    <StyledSearchItem>
      <div className="title">
        <span className="number">#</span>
        <span dangerouslySetInnerHTML={{ __html: html }} />
      </div>
      <div className="address">{text}</div>
    </StyledSearchItem>
  );
};

const AddressSearchItem = ({ title, text, term }) => {
  const html = title.replace(term, `<strong>${term}</strong>`);
  return (
    <StyledSearchItem>
      <div className="title">
        <Icon name="location" /> <span dangerouslySetInnerHTML={{ __html: html }} />
      </div>
      <div className="address-search">{text}</div>
    </StyledSearchItem>
  );
};

interface BuyProcessFormAddressProps {
  listing?: Listing;
  isEditing: boolean;
  clickedOnNewOffer?: boolean;
  onStartEdit: (step: number) => void;
  onSubmit: (listing: Listing, offerYesOrNo: string) => void;
}

const BuyProcessFormAddress: React.FC<BuyProcessFormAddressProps> = ({
  listing: listingInit,
  isEditing,
  clickedOnNewOffer,
  onStartEdit,
  onSubmit
}) => {
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({
    defaultValues: listingInit
      ? {
        address: getListingAddress(listingInit),
        address1: listingInit.address1,
        address2: listingInit.address2,
        city: listingInit.city,
        state: listingInit.state,
        zip: listingInit.zip
      }
      : undefined
  });
  const [listing, setListing] = useState<Listing | undefined>(listingInit);
  const [listings, setListings] = useState<Listing[]>([]);
  const [submitOffer, setSubmitOffer] = useState("offerYes");

  const abortController = useRef<AbortController>(null);

  const fetchListingsOptions = async (search: string) => {
    abortController.current?.abort();
    (abortController.current as any) = new AbortController();
    const searchString = search.toLowerCase();
    try {
      const listings = await searchListingsByAddressOrMlsId(
        searchString,
        abortController.current?.signal
      );
      setListings(listings);
      const mlsIds: Listing[] = [];
      const addresses: Listing[] = [];
      listings.forEach(listing => {
        if (listing.id.toLowerCase().includes(searchString)) {
          return mlsIds.push(listing);
        }
        if (listing?.addressSearch?.toLowerCase()?.includes(searchString)) {
          addresses.push(listing);
        }
      });
      const options: AutocompleteOption[] = [
        { text: 'Addresses:', disabled: true },
        ...addresses.map(item => ({
          id: item.id,
          text: (
            <AddressSearchItem title={item.address1} text={getListingAddress(item)} term={search} />
          )
        })),
        { text: 'MLS ID:', disabled: true },
        ...mlsIds.map(item => ({
          id: item.id,
          text: <MlsSearchItem title={item.id} text={getListingAddress(item)} term={search} />
        }))
      ];

      return options;
    } catch (err) {
      if (err.name !== 'AbortError') {
        notify(tryGetFirstError(err));
      }
    }
  };

  const submit = async () => {
    if (listing) onSubmit(listing, submitOffer);
  };

  const handleOptionSelect = listingId => {
    const listing = listings?.find(listing => listing.id === listingId);
    if (!listing) return;

    const { address1, address2, city, state, zip } = listing;
    reset({
      address: getListingAddress(listing),
      address1,
      address2,
      city,
      state,
      zip
    });
    setListing(listing);
  };

  if (!isEditing && listing) {
    return (
      <div className="step-info">
        <Image
          size="54x54"
          className="listing-photo"
          {...getListingImageProps(listing.images?.[0])}
        />
        <div>
          <h3 className="step-info__primary">{getListingAddress(listing)}</h3>
          <p className="step-info__secondary">MLS ID: {listing.listingMlsId}</p>
        </div>
        <Button className="step-info__edit-btn" simple onClick={() => onStartEdit(STEP)}>
          Edit
        </Button>
      </div>
    );
  }

  return (
    <StyledForm onSubmit={handleSubmit(submit)}>
      <BuyProcessHint>
        <HouseCheckmarkSVG /> Please enter the MLS ID or the address of the property
      </BuyProcessHint>
      <div className="input-group">
        <Controller
          control={control}
          name="address"
          rules={{ required: 'Required' }}
          defaultValue=""
          render={({ field, formState: { errors } }) => (
            <InputAutocomplete
              error={errors.address?.message}
              label="MLS ID or Address*"
              placeholder="Enter MLS ID or Address"
              minLength={2}
              showSpinner
              getOptions={fetchListingsOptions}
              onOptionSelect={option => handleOptionSelect(option.id)}
              style={{ gridColumn: 'span 4' }}
              {...field}
            />
          )}
        />
      </div>
      {listing && (
        <>
          <div className="input-group">
            <Input
              {...register('address1', {
                required: 'Required'
              })}
              name="address1"
              error={errors.address1?.message}
              label="Address line 1*"
              placeholder="Enter Address line 1"
            />
            <Input
              {...register('address2')}
              name="address2"
              error={errors.address2?.message}
              label="Address line 2"
              placeholder="Enter Address line 2"
            />
          </div>
          <div className="input-group">
            <Input
              {...register('city', {
                required: 'Required'
              })}
              name="city"
              error={errors.city?.message}
              label="City*"
              placeholder="Enter City"
            />
            <Controller
              control={control}
              rules={{ required: 'Required' }}
              name="state"
              defaultValue=""
              render={({ field, formState: { errors } }) => (
                <Dropdown
                  options={statesOptions}
                  label="State*"
                  placeholder="Select State"
                  error={errors.state?.message}
                  style={{ gridColumn: 'span 1' }}
                  {...field}
                />
              )}
            />
            <Input
              {...register('zip', {
                required: 'Required',
                pattern: { value: REGEXP.ZIP_CODE, message: 'Invalid ZIP Code' },
                maxLength: { value: 5, message: 'Invalid ZIP Code' }
              })}
              error={errors.zip?.message}
              label="Zip Code*"
              placeholder="Enter Zip Code"
              className="input-zip"
              style={{ gridColumn: 'span 1' }}
            />
          </div>
          {clickedOnNewOffer && (<hr />)}
          {clickedOnNewOffer && (
            <div className="input-group">
              <div className="buyprocess-form__radio-group">
                <div className="label">Do you want to create an offer?</div>
                <RadioButton 
                  {...register('offerYesOrNo')} 
                  value="offerYes" 
                  label="Yes" 
                  onClick={() => setSubmitOffer('offerYes')}
                  defaultChecked={true}
                />
                <RadioButton
                  {...register('offerYesOrNo')}
                  value="offerNo"
                  label="No"
                  onClick={() => setSubmitOffer('offerNo')}
                />
              </div>
            </div>
          )}
          <div className="buttons-bottom-container">
            {submitOffer === "offerYes" && (
              <Button className="submit-button">
                Next
              </Button>
            )}
            {submitOffer === "offerNo" && (
              <Button className="submit-button">
                Submit
              </Button>
            )}
          </div>
        </>
      )}
    </StyledForm>
  );
};

export default BuyProcessFormAddress;

const StyledForm = styled.form`
  .listing-lookup-info {
    h3 {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }

    p {
      margin: 8px 0;
    }

    .thumbnail {
      float: left;
      margin-right: 30px;
    }

    &__address {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      margin-top: 20px !important;
    }

    &__price {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    }

    &__price-label {
      font-size: 16px;
      line-height: 24px;
      color: ${props => props.theme.colors.grayDark};
    }
  }

  hr {
    border-style: dashed;
    border-color: #c4c4c4;
    margin: 20px 0;
  }

  .input-group {
    margin-bottom: 14px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 14px;

    .input {
      grid-column: span 2;
    }

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      grid-template-columns: 1fr;
    }
  }

  .input-autocomplete {
    &__options-list {
      max-height: 216px;
      overflow: auto;
      max-width: 483px;
    }
  }

  .input-city {
    grid-column: 1 / span 2;
  }

  .dropdown {
    width: 100%;
  }

  .buyprocess-form__radio-group {
    margin: 0 0 12px 0;
    width: 100%;
  }

  .buyprocess-form__radio-group {
    & > .label {
      font-size: 10px;
      margin-bottom: 12px;
    }
    .radiobutton {
      margin-right: 20px;
    }
  }

  .buttons-bottom-container {
    margin-top: auto;
    display: flex;
    justify-content: flex-end;

    .button {
      height: 36px;

      &.submit-button {
        margin-left: 20px !important;
      }
    }
  }
`;

const StyledSearchItem = styled.div`
  .title {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    line-height: 22px;
  }
  .number {
    width: 16px;
  }
  .address,
  .address-search {
    padding-left: 16px;
    font-size: 10px;
    line-height: 19px;
    color: #8892a0;
  }
  .address-search {
    padding-left: 20px;
  }
  .icon {
    margin-right: 4px;
    fill: #000;
  }
`;
