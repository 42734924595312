import React from 'react';
import dayjs from 'dayjs';

import styled from '@emotion/styled';
import useActivity from './useActivity';
import {
  OfferAddedIcon,
  ScheduleViewRequestIcon,
  TaskIcon,
  DocumentAddedIcon,
  OfferAcceptedIcon,
  OfferRejectedIcon
} from './activityIcons';

const getIconByActitivyType = (type) => {
  switch (type) {
    case 'offer_create':
      return <OfferAddedIcon className="activity-item__icon" />;
    case 'offer_accepted':
      return <OfferAcceptedIcon className="activity-item__icon" />;
    case 'offer_rejected':
      return <OfferRejectedIcon className="activity-item__icon" />;
    case 'schedule_tour':
      return <ScheduleViewRequestIcon className="activity-item__icon" />;
    case 'task':
      return <TaskIcon className="activity-item__icon" />;
    case 'document':
      return <DocumentAddedIcon className="activity-item__icon" />;
    default:
      return <div className="icon-placeholder" />;
  }
};

const WorkspaceActivityTab = () => {
  const { activities } = useActivity();

  return (
    <StyledWorkspaceActivityTab>
      {activities.length !== 0 ?
        activities.map(([key, items]) => (
          <div key={key}>
            <h3 className="date-title">{key}</h3>
            {(items as WorkspaceActivityLogItem[]).map((item: WorkspaceActivityLogItem) => (
              <div className="activity-item" key={item.id}>
                {getIconByActitivyType(item.type)}
                <div className="activity-item__info">
                  <div className="activity-item__title">{item.text}</div>
                  <div className="activity-item__subtitle">
                    {[item.name, dayjs(item.createdOn).format('hh:mm A')]
                      .filter(Boolean)
                      .join(' • ')}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )) : (
          <div className="not-found-message">No activity yet.</div>
        )}
    </StyledWorkspaceActivityTab>
  );
};

export default WorkspaceActivityTab;

const StyledWorkspaceActivityTab = styled.div`
  margin: 24px 0;

  .date-title {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: ${props => props.theme.colors.grayDark};
  }

  .activity-item {
    height: 36px;
    padding: 16px 0;
    box-sizing: content-box;
    border-bottom: 1px solid #f1f1f1;
    display: flex;
    align-items: center;

    &:last-of-type {
      border-bottom: none;
    }


    &__icon {
      flex-shrink: 0;
      margin-right: 12px;
    }

    &__title {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 4px;
    }

    &__subtitle {
      font-size: 12px;
      line-height: 16px;
      color: ${props => props.theme.colors.grayDark};
    }

    .icon-placeholder {
      height: 36px;
      width: 36px;
      background: #c4c4c4;
      border-radius: 3px;
      flex-shrink: 0;
      margin-right: 12px;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .activity-item {
      height: auto;
      margin: 0 0 0 16px;
    }
  }
`;
