import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { camelToNormal } from 'utils/string';

import { Button, Checkbox, Icon } from '@xchange/uikit';
import { TextEditor } from 'components';
import WorkspaceContext from '../WorkspaceContext';
import { REGEXP } from 'consts';
interface ShareDocumentFormProps {
  disableActions?: boolean;
  documents: WorkspaceDocument[];
  onCancel: () => void;
  onSave: (values: {
    ids: string;
    customEmails?: string[];
    message?: string;
    zipFormat?: boolean;
  }) => void;
}

const ShareDocumentForm: React.FC<ShareDocumentFormProps> = ({
  disableActions,
  documents: documentsProps,
  onCancel,
  onSave,
  ...props
}) => {
  const { members, fetchMembers } = useContext(WorkspaceContext);
  const [documents, setDocuments] = useState<WorkspaceDocument[]>(documentsProps);
  const [emails, setEmails] = useState<string[]>([]);
  const [externalEmails, setExternalEmails] = useState<string>('');
  const [compressFiles, setCompressFiles] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const allEmails = useMemo(() => {
    const externalEmailsSplitted = externalEmails ? externalEmails.split(',') : [];
    return [...emails, ...externalEmailsSplitted];
  }, [emails, externalEmails]);

  const onFileRemove = (fileId: number) => {
    setDocuments(documents.filter(document => document.id !== fileId));
  };

  const onSelectEmail = (checked: boolean, recipientEmail: string) => {
    if (checked) return setEmails(v => [...v, recipientEmail]);

    setEmails(emails.filter(email => email !== recipientEmail));
  };

  const handleSave = () => {
    var i;
    var idArray = [];
    for(i = 0; i < Object.keys(documents).length; i++){
      idArray.push([documents[i].id,documents[i].isExternal?1:0,documents[i].name,documents[i].doctype]);
    }
    const values = {
      ids: JSON.stringify(idArray),
      zipFormat: compressFiles ? 1 : 0,
      customEmails: allEmails,
      message
    };
    console.log(values);
    onSave(values);
  };

  useEffect(() => {
    if (!members.length) fetchMembers();
  }, [fetchMembers, members.length]);

  return (
    <StyledShareDocumentForm
      className="share-document"
      {...props}>
      <div className="share-document__section">
        <Button secondary onClick={onCancel}>
          <Icon name="arrow-simple" />
        </Button>
        <h3>Email Document(s)</h3>
      </div>
      <div className="share-document__section">
        <h4>Document(s):</h4>
        {documents.length > 0 && (
          <div className="share-document__files">
            {documents.map(file => (
              <span key={file.id} className="file">
                <Icon name="fc-file" />
              &nbsp;&nbsp;
                <span className="file__name">
                  {file.name}
                </span>&nbsp;&nbsp;
                <Icon name="mini-x" size={8} onClick={() => onFileRemove(file.id)} />
              </span>
            ))}
            <Checkbox
              label="Compress files"
              checked={compressFiles}
              onChange={e => setCompressFiles(e.target.checked)}
            />
          </div>
        )}
      </div>
      <div className="share-document__section">
        <h4>Recipients:</h4>
        <div className="share-document__checkboxes">
          {members.map(member => (
            <div key={member.id} className="share-document__checkbox">
              <Checkbox
                label={
                  <>
                    {member.name || member.email} ({camelToNormal(member.role)})
                    {(member.name && member.name !== member.email) ? (
                      <> - <span>{member.email}</span></>) : null
                    }
                  </>
                }
                checked={emails.includes(member.email)}
                onChange={(e) => onSelectEmail(e.target.checked, member.email)}
              />
              {(member.secondaryEmail && member.secondaryEmail !== member.email) && (
                <Checkbox
                  label={
                    <>
                      {member.name || member.secondaryEmail} ({camelToNormal(member.role)})
                      {(member.name && member.name !== member.secondaryEmail) ? (
                        <> - <span>{member.secondaryEmail}</span></>) : null
                      }
                    </>
                  }
                  checked={emails.includes(member.secondaryEmail)}
                  onChange={(e) => onSelectEmail(e.target.checked, member.secondaryEmail || '')}
                />
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="share-document__section">
        <h4>List all external recipients here, comma separated:</h4>
        <div className="share-document__external">
          To:
          <input
            value={externalEmails}
            onChange={e => setExternalEmails(e.target.value)}
          />
        </div>
      </div>
      <div className="share-document__section">
        <h4>Add custom message:</h4>
        <TextEditor value={message} onChange={setMessage} height={{ desktop: 200, mobile: 136 }} />
      </div>
      <div className="share-document__buttons">
        <Button
          secondary
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          disabled={disableActions || !documents.length}
        >
          Send
        </Button>
      </div>
    </StyledShareDocumentForm>
  );
};

export default ShareDocumentForm;

const StyledShareDocumentForm = styled.div`
  padding-bottom: 48px;

  h3 {
    margin-left: 14px;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
  }
  .share-document {
    &__section {
      padding: 8px 0;
    }
    &__checkboxes {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 8px;
      grid-column-gap: 16px;
    }
    &__checkbox {
      border: 1px solid ${props => props.theme.colors.alto};
      border-radius: ${props => props.theme.misc.borderRadius};
    }
    &__external {
      display: flex;
      flex-flow: nowrap;
      align-items: center;
      padding: 12px;
      color: ${props => props.theme.colors.grayDark};
      border: 1px solid ${props => props.theme.colors.alto};
      border-radius: ${props => props.theme.misc.borderRadius};
      input {
        width: 100%;
        padding: 0 4px;
        border: none;
        outline: none;
        color: #000;
        line-height: 16px;
      }
    }
    &__buttons {
      position: fixed;
      z-index: 1001;
      width: 1024px;
      bottom: 0;
      right: 0;
      padding: 8px 20px;
      text-align: right;
      background-color: #F8F8F8;
      .button + .button {
        margin-left: 16px;
      }
    }
    h4 {
      margin-bottom: 10px;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
    }
  }
  .file {
    margin: 0 8px 8px 0;
    display: inline-flex;
    align-items: center;
    padding: 4px 8px;
    font-size: 12px;
    line-height: 16px;
    border: 1px solid #000;
    border-radius: ${props => props.theme.misc.borderRadius};
    &__name {
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .icon-mini-x {
      fill: ${props => props.theme.colors.red};
      cursor: pointer;
    }
  }
  .checkbox {
    margin: 8px 0 8px 16px;
    label > span {
      color: ${props => props.theme.colors.grayDark};
    }
    & + .checkbox {
      margin-left: 40px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding-bottom: 16px;
    .share-document {
      &__checkboxes {
        grid-template-columns: 1fr;
        grid-row-gap: 12px;
      }
      &__checkbox {
        padding: 0 10px;
      }
      &__buttons {
        position: relative;
        padding: 8px 0;
        background: none;
        width: 100%;
      }
    }
    .file {
      margin: 0 8px 8px 0;
    }
    .checkbox {
      display: flex;
      margin: 8px 0;
    }
  }
`;
