import React, { useCallback, useEffect, useState } from 'react';
import { getListing } from 'api/listings';
import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import { QueryParamConfig, StringParam, useQueryParams, withDefault } from 'use-query-params';
import {
  getMembers,
  deleteListing as deleteListingRequest,
  addListingMlsId as addListingMlsIdRequest
} from './api';

interface WorkspaceContextValues {
  workspaceOpen: boolean;
  openWorkspace: (listingId: string) => void;
  closeWorkspace: () => void;
  setWorkspaceListingId: (listingId: string) => void;
  listing: Listing | undefined;
  setListing: (listing: Listing) => void;
  listingId: string;
  workspaceTab: WorkspaceTabKey;
  setWorkspaceTab: (workspaceTab: WorkspaceTabKey) => void;
  fetchMembers: () => Promise<void>;
  members: WorkspaceMember[];
  setMembers: React.Dispatch<React.SetStateAction<WorkspaceMember[]>>;
  deleteListing: (value: string, callback?: () => void) => void;
  addListingMlsId: (value: string) => void;
  fetchListing: () => void;
}

const WorkspaceContext = React.createContext({} as WorkspaceContextValues);

const defaultTab: WorkspaceTabKey = 'documents';

export const WorkspaceContextWrapper = ({ children }) => {
  const [query, setQuery] = useQueryParams({
    listingId: withDefault(StringParam, ''),
    workspaceTab: withDefault(StringParam, defaultTab) as QueryParamConfig<WorkspaceTabKey>
  });
  const [members, setMembers] = useState<WorkspaceMember[]>([]);
  const { listingId, workspaceTab } = query;

  const [listing, setListing] = useState<Listing>();
  const workspaceOpen = Boolean(listingId);

  const setWorkspaceTab = (workspaceTab: WorkspaceTabKey) => {
    setQuery({ workspaceTab }, 'replaceIn');
  };

  const openWorkspace = (listingId: string) => {
    setQuery({ listingId, workspaceTab: defaultTab }, 'replaceIn');
  };

  const setWorkspaceListingId = (listingId: string) => {
    if (!listingId) return;
    setQuery({ listingId }, 'replaceIn');
  };

  const closeWorkspace = () => {
    setListing(undefined);
    setQuery({ listingId: undefined, workspaceTab: undefined });
    setMembers([]);
  };

  const fetchMembers = useCallback(async () => {
    if (!listingId) return;
    try {
      const members = await getMembers(listingId);
      setMembers(members);
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  }, [listingId]);

  const fetchListing = useCallback(async () => {
    try {
      const listing = await getListing(listingId!);
      setListing(listing);
    } catch (err) {
      notify(tryGetFirstError(err) || err.message);
    }
  }, [listingId]);

  const deleteListing = async (listingId: string, callback?: () => void) => {
    try {
      await deleteListingRequest(listingId);
      closeWorkspace();
      notify('Listing deleted successfully');
      if (callback) callback();
    } catch (err) {
      notify(tryGetFirstError(err) || err.message);
    }
  };

  const addListingMlsId = async (listingMlsId: string) => {
    try {
      const mlsListing = await addListingMlsIdRequest(listingId, listingMlsId);
      setQuery({ listingId: mlsListing.listingId }, 'replaceIn');
      notify('MLS ID added successfully');
    } catch (err) {
      notify(tryGetFirstError(err) || err.message);
    }
  };

  useEffect(() => {
    if (listingId) {
      fetchListing();
      fetchMembers();
    }
  }, [listingId, fetchListing, fetchMembers]);

  const values: WorkspaceContextValues = {
    openWorkspace,
    closeWorkspace,
    setWorkspaceListingId,
    workspaceOpen,
    workspaceTab,
    setWorkspaceTab,
    listing,
    setListing,
    listingId,
    fetchMembers,
    members,
    setMembers,
    deleteListing,
    addListingMlsId,
    fetchListing
  };
  return <WorkspaceContext.Provider value={values}>{children}</WorkspaceContext.Provider>;
};

export default WorkspaceContext;
