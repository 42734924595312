import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';
import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import { Button, Icon } from '@xchange/uikit';
import { ConfirmationDialog, SideMenu } from 'components';
import BuyProcess from 'views/BuyProcess';
import DocumentCreatorForm from 'views/DocumentCreator/DocumentCreatorForm';
import {
  createDocumentFromTemplate,
  sendRequest,
  getExternalDocumentForm,
  generateDocumentData as generateDocumentDataRequest
} from 'api/documents';
import NewDocumentForm from './NewDocumentForm';
interface CreateNewMenuProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onSubmit'> {
  templates: FormTemplate[];
  listing: Listing | undefined;
  onClose: () => void;
  onSuccess: (value?: boolean) => void;
  onClickListingAgreement: () => void;
}

const CreateNewMenu: React.FC<CreateNewMenuProps> = ({
  templates,
  listing,
  onSuccess,
  onClose,
  onClickListingAgreement
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');
  const [buyProcessIsOpen, setBuyProcessIsOpen] = useState(false);
  const [openConfirmClientRequest, setOpenConfirmClientRequest] = useState<boolean>(false);
  const [generateDocumentData, setGenerateDocumentData] = useState<DocumentFormField[] | null>(null);
  const [formValues, setFormValues] = useState<{ [key: string]: string | number; }>();
  const { status: listingStatus, type: listingType } = listing || {} as Listing;
  const contactEmail = listingType === 'Buy' ? listing?.buyer?.email : listing?.seller?.email;
  const isActiveListing = listingStatus === 'Active' || listingStatus === 'Offer Placed';
  var selectedContractType = templates.find(template => template.displayName === selectedTemplate)?.name || '';
  var [contractTypes, setSelectedcontractTypes] = useState<string>('');

  var filteredTemplates = templates.filter(item => item.listingStatus.includes(listingStatus))
      .filter(item => isActiveListing ? item.direction === listingType : item.direction.includes(listingType))
      .sort((a, b) => a.order - b.order);

  const generateDocumentForm = async values => {
    const { broker_filling } = values;
    var selectedTemplates = [];
    Object.keys(values).forEach(function(key) {
      if(key!=='email' && key!=='broker_filling' && values[key]){
        selectedTemplates.push(key);
      }  
    });
    selectedContractType = JSON.stringify(selectedTemplates);
    try {
      //var types_needed = 
      const redirectUrl = await sendRequest({
        email: values.email,
        broker_filling: broker_filling ? 1 : 0,
        listing_id: listing?.listingId!,
        contract_type: selectedContractType
      });

      if (broker_filling) {
        return window.open(redirectUrl);
      };
      notify(`Email sent to ${values.email}`);
      onSuccess();
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const templateButtons = useMemo(() => {
    var html = filteredTemplates.filter(item => (!item.displayName.includes('Disclosure') && !item.displayName.includes('Commission Statement'))).map(item => (
      <button
        key={item.name}
        className={clsx('template-button', { active: selectedTemplate === item.displayName })}
        onClick={() => setSelectedTemplate(item.displayName)}>
        {item.displayName}
      </button>
    ));
    if (listingType === 'Sell'){
      html.push(<button
        className={clsx('template-button', { active: selectedTemplate === 'Disclosures' })}
        onClick={() => setSelectedTemplate('Disclosures')}>
        Disclosures
      </button>)
      html.push(<button
        className={clsx('template-button', { active: selectedTemplate === 'Commission Statement' })}
        onClick={() => {
          setSelectedTemplate('Commission Statement');
          setSelectedcontractTypes(JSON.stringify(['cdd_brokercommissionstatement']));
        }}>
        Broker Commission Statement
      </button>)
    }
    
    return html
  }, [templates, selectedTemplate, listingStatus, listingType]);

  const documentsToShow = useMemo(() => {
    if (isActiveListing && listingType === 'Sell') {
      return (
        <>
          <button
            className={clsx('template-button', { active: selectedTemplate === 'Listing Agreement' })}
            onClick={onClickListingAgreement}>
            Listing Agreement
          </button>
          <button
            className={clsx('template-button', { active: selectedTemplate === 'Disclosures' })}
            onClick={() => setSelectedTemplate('Disclosures')}>
            Disclosures
          </button>
          <button
            className={clsx('template-button', { active: selectedTemplate === 'Commission Statement' })}
            onClick={() => {
              setSelectedTemplate('Commission Statement');
              setSelectedcontractTypes(JSON.stringify(['cdd_brokercommissionstatement']));
            }}>
            Broker Commission Statement
          </button>
        </>
      );
    }
    if (isActiveListing && listingType === 'Buy') {
      return (
        <button
          className="template-button" onClick={() => {
            setSelectedTemplate('Offer');
            setBuyProcessIsOpen(true);
          }}
        >
          Offer
        </button>
      );
    }
    if (listingStatus === 'Under Contract') {
      return templateButtons;
    }
  }, [listingStatus, listingType, selectedTemplate]);

  useEffect(() => {
    if (selectedTemplate === 'Mutual Cancellation') hadleCreateDocumentSubmit({
      ld_cancellation: true,
      broker_filling: true
    });
  }, [selectedTemplate]);

  const closeConfirmClientRequest = () => setOpenConfirmClientRequest(false);

  const confirmClientRequest = async () => {
    await generateDocumentForm(formValues);
    closeConfirmClientRequest();
  };

  const closeGenerateDocument = () => setGenerateDocumentData(null);

  const hadleCreateDocumentSubmit = values => {
    var selectedTemplates = [];
    Object.keys(values).forEach(function(key) {
      if(key.includes('ld_') && values[key]){
        selectedTemplates.push(key);
      }  
    });
    selectedContractType = JSON.stringify(selectedTemplates);
    setSelectedcontractTypes(selectedContractType);
    setFormValues(values);

    if (!values.broker_filling) {
      return setOpenConfirmClientRequest(true);
    }

    fetchGenerateDocumentData();
  };

  const fetchGenerateDocumentData = async () => {
    try {
      const generateDocumentData = await getExternalDocumentForm(selectedContractType);
      setGenerateDocumentData(generateDocumentData);
    } catch (err) {
      const errorMessage = tryGetFirstError(err);
      notify(errorMessage);
    }
  };

  const submitDocumentCreatorForm = async (values) => {
    try {
      const documentId = await generateDocumentDataRequest(
        values,
        listing?.id!,
        contractTypes
      );
      setGenerateDocumentData(null);
      notify(`${selectedTemplate} created successfully`);

      if (documentId) window.open(`/pdf-editor?id=${documentId}`);
    } catch (err) {
      const errorMessage = tryGetFirstError(err);
      notify(errorMessage);
    }
  };

  const submit = async values => {
    if (!selectedTemplate) return;
    if (selectedTemplate.includes('Disclosure')) {
      return hadleCreateDocumentSubmit(values);
    }

    if (selectedTemplate.includes('Commission Statement')) {
      return submitDocumentCreatorForm(values);
    }

    try {
      const formValues = {
        listing_id: listing!.id,
        expense_sum: values.expenseSum,
        comment: values.comment,
        seller_broker_commission_percent: values.sellerBrokerCommissionPercent,
        seller_broker_commission_flat_rate_extract: values.sellerBrokerCommissionFlatRateExtract,
        seller_broker_commission_flat_rate_add: values.sellerBrokerCommissionFlatRateAdd,
        buyer_broker_commission_percent: values.buyerBrokerCommissionPercent,
        buyer_broker_commission_flat_rate_extract: values.buyerBrokerCommissionFlatRateExtract,
        buyer_broker_commission_flat_rate_add: values.buyerBrokerCommissionFlatRateAdd,
        mls_fee: values.mlsFee,
        county_taxes: values.countyTaxes,
        processing_fees: values.processingFees,
        endorsements: values.endorsements
      };
      const { formId } = await createDocumentFromTemplate(formValues, selectedContractType);
      window.open(
        `/form-creation/edit?id=${formId}&prop_id=${listing!.id}&docType=${selectedContractType}`
      );
      onSuccess(true);
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  return (
    <>
      <StyledCreateNewMenu>
        <h2 className="title">
          <Button secondary onClick={onClose} className="go-back-button">
            <Icon name="arrow-simple" size={16} />
          </Button>{' '}
          Create Document
        </h2>
        <div className="upload-document-columns">
          <div className="left">
            {documentsToShow}
          </div>
          <div className="right">
            {selectedTemplate ? (
              <NewDocumentForm
                data={{ email: contactEmail }}  
                listing={listing} 
                template={selectedTemplate}
                templates={filteredTemplates}
                onSubmit={submit}
              />
            ) : (
                'Select document type'
              )}
          </div>
        </div>
        <div className="form-buttons">
          <Button secondary onClick={onClose}>
            Cancel
          </Button>
          <Button form="newdocform" disabled={!selectedTemplate}>
            Create
          </Button>
        </div>
      </StyledCreateNewMenu>
      <BuyProcess
        open={buyProcessIsOpen}
        listing={listing}
        onClose={() => setBuyProcessIsOpen(false)}
        onSave={() => {
          setBuyProcessIsOpen(false);
          onSuccess(true);
        }}
      />
      <ConfirmationDialog
        modalTitle={`Create ${selectedTemplate}`}
        open={openConfirmClientRequest}
        buttonLabels={{ cancel: 'Dismiss', submit: 'Confirm' }}
        onSubmit={confirmClientRequest}
        onClose={closeConfirmClientRequest}>
        <p>
          Your client will receive an email with the request to create {selectedTemplate}.
        </p>
      </ConfirmationDialog>
      <StyledDocumentCreator
        title="Create Document"
        open={Boolean(generateDocumentData)}
        onClose={closeGenerateDocument}>
        {generateDocumentData ? (
          <DocumentCreatorForm
            fields={generateDocumentData}
            onSubmit={submitDocumentCreatorForm}
          />
        ) : null}
      </StyledDocumentCreator>
    </>
  );
};

export default CreateNewMenu;

const StyledCreateNewMenu = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    margin: 32px 0 20px;
    display: flex;
    align-items: center;
  }

  .upload-document-columns {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    height: 100%;
    overflow: auto;
    margin-bottom: 50px;
    grid-gap: 32px;

    & > * {
      width: 100%;
    }
  }

  .template-button {
    font-size: 14px;
    line-height: 15px;
    height: 80px;
    border: 1px solid #8b8b8b;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 8px;
    background: none;
    cursor: pointer;
    outline: none;

    &.active {
      background: ${props => props.theme.colors.lightGray};
    }

    &:hover {
      border: 1px solid ${props => props.theme.colors.red};
    }
  }

  .extra-fields-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 32px;
  }

  .go-back-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
  }

  .left {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 80px 80px auto;
    grid-gap: 16px;
    border-right: 1px solid ${props => props.theme.colors.borderColor};
    padding-right: 30px;
    font-size: 16px;
    line-height: 24px;
  }

  .right {
    font-size: 16px;
    line-height: 24px;
    color: #8b8b8b;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .form-buttons {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 24px;
    width: calc(100% + 40px);
    margin: 0 -20px -24px -20px !important;
    padding: 8px 27px;
    background: #f8f8f8;

    .button:nth-of-type(1) {
      margin-right: 16px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .upload-document-columns {
      display: block;
      .left {
        grid-template-columns: 1fr 1fr;
        grid-gap: 12px;
        margin: 0 0 32px 0;
        padding-right: 0;
      }
    }
    .form-buttons {
      margin: 0 -10px -16px -10px !important;
      width: calc(100% + 20px);
      padding: 8px 10px;
      button {
        width: 100%;
      }
    }
  }
`;

const StyledDocumentCreator = styled(SideMenu)`
  & > .side-menu {
    width: 1024px;
  }
`;
