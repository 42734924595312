import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import styled from '@emotion/styled';

import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Dropdown, { DropdownOption } from 'components/Dropdown';
import Input from 'components/Input';
import { InputDate } from '@xchange/uikit';
import { CalendarItem } from 'views/CalendarPage/calendar.d';

interface EditTaskFormProps {
  initialData?: Partial<CalendarItem>;
  usersOptions?: DropdownOption[];
  documentsOptions?: DropdownOption[];
  onSubmitNew: (values) => void;
  onSubmitEdit: (values) => void;
}

const EditTaskForm: React.FC<EditTaskFormProps> = ({
  initialData,
  usersOptions,
  documentsOptions,
  onSubmitNew,
  onSubmitEdit
}) => {
  const restrictEdit = Boolean(initialData?.id) && !initialData!.editable;
  const [linkDocument, setLinkDocument] = useState(Boolean(initialData?.docId));
  const {
    register,
    control,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {
      ...initialData,
      dateType: 'task',
      date: initialData?.start?.format('MM/DD/YYYY')
    }
  });
  const isEditing = Boolean(initialData?.id);

  const submit = form => {
    if (isEditing) onSubmitEdit({ ...form, id: initialData?.id });
    else onSubmitNew(form);
  };

  return (
    <StyledForm onSubmit={handleSubmit(submit)}>
      <Input
        {...register('title', { required: 'Required' })}
        error={errors.title?.message}
        label="Title"
        placeholder="Enter"
        disabled={restrictEdit}
        data-cy="input_title"
      />
      <Controller
        control={control}
        rules={{
          required: 'Required',
          pattern: {
            value: /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/,
            message: 'Invalid date'
          }
        }}
        name="date"
        defaultValue={dayjs().format('MM/DD/YYYY')}
        render={({ field, formState: { errors } }) => (
          <InputDate
            inputProps={{
              label: 'Date',
              error: errors.date?.message,
              disabled: restrictEdit,
              'data-cy': 'input_date'
            }}
            {...field}
          />
        )}
      />

      {initialData?.propertyName && (
        <Input {...register('propertyName')} label="Project" disabled data-cy="input_project" />
      )}

      <Controller
        control={control}
        name="assigneeId"
        render={({ field, formState: { errors } }) => (
          <Dropdown
            search
            options={usersOptions}
            label={
              <>
                Assigned to <span className="optional">(optional)</span>
              </>
            }
            placeholder="Select"
            disabled={restrictEdit}
            error={errors.assigneeId?.message}
            data-cy="dropdown_asignee"
            {...field}
          />
        )}
      />
      <Checkbox
        checked={linkDocument}
        onChange={e => setLinkDocument(e.target.checked)}
        label="Link completion to Document"
        disabled={isEditing}
        data-cy="checkbox_link_doc"
      />
      {linkDocument && (
        <Controller
          control={control}
          rules={{ required: 'Required' }}
          name="docId"
          render={({ field, formState: { errors } }) => (
            <Dropdown
              search
              options={documentsOptions}
              label="Document"
              placeholder="Choose"
              error={errors.docId?.message}
              disabled={isEditing}
              data-cy="dropdown_document"
              {...field}
            />
          )}
        />
      )}
      <Button type="submit">{isEditing ? 'Edit' : 'Add new'}</Button>
    </StyledForm>
  );
};

export default EditTaskForm;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  & > * {
    margin-top: 16px;
  }

  & > .button {
    height: 40px;
  }

  .optional {
    color: ${props => props.theme.colors.grayDark};
  }
`;
