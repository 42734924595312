/* eslint-disable no-nested-ternary */
import React, { useRef, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import { formatCurrency } from 'utils/rifmFormatters';
import { validateInputPercentage } from 'utils/validation';
import { InputCurrency, InputPercentage } from 'components';
import { Input, Button, RadioButton, Dropdown, DropdownOption } from '@xchange/uikit';
import { BuyProcessFinancialsData } from './types';

const STEP = 3;

const paymentOptions: DropdownOption[] = [
  {
    value: 'cashNoMortgage',
    text: 'Cash Transaction With No Mortgage'
  },
  {
    value: 'cashMortgageAllowed',
    text: 'Cash Transaction, Mortgage Allowed'
  }
];

const beholdingOptions = [
  { value: 'SellerAttorney', text: 'Seller Attorney' },
  { value: 'SellerBroker', text: 'Seller Broker' },
  { value: 'BuyerAttorney', text: 'Buyer Attorney' },
  { value: 'BuyerBroker', text: 'Buyer Broker' },
  { value: 'TitleCompany', text: 'Title Company' }
];

const loanTypeOptions: DropdownOption[] = ['Conventional', 'FHA', 'VA', 'USDA', 'Other'].map(
  (key: string) => ({
    value: key,
    text: key
  })
);
interface BuyProcessFormPaymentMethodProps {
  listing: Listing;
  isEditing: boolean;
  onStartEdit: (step: number) => void;
  defaultValues?: Partial<BuyProcessFinancialsData>;
  onSubmit: (values: BuyProcessFinancialsData) => void;
}

const BuyProcessFormFinancials: React.FC<BuyProcessFormPaymentMethodProps> = ({
  listing,
  isEditing,
  defaultValues,
  onStartEdit,
  onSubmit
}) => {
  const payWithCash = defaultValues?.paymentMethod?.toLowerCase().includes('cash');
  const {
    register,
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      ...defaultValues,
      paymentMethod: payWithCash ? 'cash' : 'financing',
      loanType: defaultValues?.loanTypeOther ? 'Other' : defaultValues?.loanType,
      offerAmount: defaultValues?.offerAmount
    }
  });
  const [paymentMethod, loanType] = watch(['paymentMethod', 'loanType']);
  const paymentContainer = useRef<HTMLDivElement>(null);

  const submit = values => {
    const loanBase = values.loanBase?.[1] || values.loanBase?.[0];
    const loanType = values.loanType === 'Other' ? values.loanTypeOther : values.loanType;
    onSubmit({ ...values, loanBase, loanType });
  };

  if (defaultValues && !isEditing)
    return (
      <>
        <div className="step-info">
          <div className="step-info__content">
            <h3 className="step-info__primary">{formatCurrency(defaultValues.offerAmount)}</h3>
            <span>{payWithCash ? 'Cash' : 'Financing'}</span>
            {payWithCash && (
              <span>
                {paymentOptions.find(option => option.value === defaultValues.transactionCash)?.text}
              </span>
            )}
            <span>Earnest Money 1: {formatCurrency(defaultValues.earnest_money_1) || '-'}</span>
            <span>Earnest Money 2: {formatCurrency(defaultValues.earnest_money_2) || '-'}</span>
            <span>
              Closing Costs Credit: {formatCurrency(defaultValues.closing_cost_credit) || '-'}
            </span>
          </div>
          <Button className="step-info__edit-btn" simple onClick={() => onStartEdit(STEP)}>
            Edit
          </Button>
        </div>
      </>
    );

  return (
    <StyledBuyPFormrocessPaymentMethod className="finance-form" onSubmit={handleSubmit(submit)}>
      <Controller
        control={control}
        name="offerAmount"
        rules={{ required: 'Required' }}
        render={({ field, formState: { errors } }) => (
          <InputCurrency
            label="Offer Amount*"
            placeholder="Enter offer amount"
            error={errors.offerAmount?.message}
            {...field}
            className="finance-form__input"
          />
        )}
      />

      <p className="section__title">Amount of earnest money</p>
      <div className="finance-form__input-container">
        <Controller
          control={control}
          name="earnest_money_1"
          render={({ field, formState: { errors } }) => (
            <InputCurrency
              label="1st Earnest Money"
              placeholder="Enter 1st earnest money"
              error={errors.earnest_money_1?.message}
              {...field}
              className="finance-form__input"
            />
          )}
        />
        <Input
          {...register('earnest_money_days', {
            pattern: {
              value: /\b(?:[1-9]|[1-5]\d|60)(?!\S)\b/g,
              message: 'Value must be greater than 0 and less than or equal to 60'
            }
          })}
          error={errors.earnest_money_days?.message}
          label="Number of Days"
          placeholder="Enter number of days"
          type="number"
        />
      </div>
      <div className="finance-form__input-container">
        <Controller
          control={control}
          name="earnest_money_2"
          render={({ field, formState: { errors } }) => (
            <InputCurrency
              label="2nd Earnest Money"
              placeholder="Enter 2nd earnest money"
              error={errors.earnest_money_2?.message}
              className="finance-form__input"
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="earnest_money_beholding"
          render={({ field, formState: { errors } }) => (
            <Dropdown
              options={beholdingOptions}
              error={errors.earnest_money_beholding?.message}
              label="Escrowee"
              placeholder="Select"
              className="finance-form__input"
              {...field}
            />
          )}
        />
      </div>

      <hr />

      <Controller
        control={control}
        name="closing_cost_credit"
        render={({ field, formState: { errors } }) => (
          <InputCurrency
            label="Closing Cost Credit"
            placeholder="Enter closing cost credit"
            error={errors.closing_cost_credit?.message}
            className="finance-form__input"
            {...field}
          />
        )}
      />

      <hr />

      <p className="section__title">Payment method</p>
      <div className="finance-form__radio-group">
        <RadioButton {...register('paymentMethod')} value="cash" label="Cash" buttonLike />
        <RadioButton {...register('paymentMethod')} value="financing" label="Financing" buttonLike />
      </div>

      {paymentMethod ? (
        paymentMethod === 'cash' ? (
          <div ref={paymentContainer}>
            <Controller
              control={control}
              rules={{ required: 'Required' }}
              name="transactionCash"
              render={({ field, formState: { errors } }) => (
                <Dropdown
                  options={paymentOptions}
                  error={errors.transactionCash?.message}
                  label="Cash Transaction With No Mortgage or Cash Transaction, Mortgage Allowed*"
                  placeholder="Select"
                  className="finance-form__input"
                  {...field}
                />
              )}
            />
          </div>
        ) : (
            <div ref={paymentContainer}>
              <hr />
              <div className="finance-form__radio-group">
                <div className="label">Fixed or adjustable loan?</div>
                <RadioButton {...register('loanFixedOrAdjustable')} value="fixed" label="Fixed" />
                <RadioButton
                  {...register('loanFixedOrAdjustable')}
                  value="adjustable"
                  label="Adjustable"
                />
              </div>
              <div className="finance-form__input-container">
                <Controller
                  control={control}
                  name="loanType"
                  defaultValue="Conventional"
                  render={({ field: { onChange, ...field }, formState: { errors } }) => (
                    <Dropdown
                      options={loanTypeOptions}
                      error={errors.loanType?.message}
                      label="Loan Type"
                      placeholder="Select loan type"
                      className="finance-form__input"
                      onChange={value => {
                        if (value !== 'Other') setValue('loanTypeOther', undefined);
                        onChange(value);
                      }}
                      {...field}
                    />
                  )}
                />
                {loanType === 'Other' ? (
                  <Input
                    {...register('loanTypeOther')}
                    label="Loan Type (Other)"
                    placeholder="Enter"
                  />
                ) : (
                    <div className="empty" />
                  )}
                <Controller
                  control={control}
                  rules={{
                    validate: validateInputPercentage
                  }}
                  name="interestRate"
                  render={({ field: { value, onChange, onBlur }, formState: { errors } }) => (
                    <InputPercentage
                      label="Max Interest Rate"
                      placeholder="Enter percentage"
                      value={value}
                      error={errors.interestRate?.message}
                      className="finance-form__input"
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                />
                <Controller
                  control={control}
                  rules={{
                    validate: validateInputPercentage
                  }}
                  name="loanAmount"
                  render={({ field: { value, onChange, onBlur }, formState: { errors } }) => (
                    <InputPercentage
                      label="Loan Amount"
                      placeholder="Enter percentage"
                      value={value}
                      error={errors.loanAmount?.message}
                      className="finance-form__input"
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="selectLoanPeriod"
                  render={({ field, formState: { errors } }) => (
                    <Dropdown
                      options={[
                        { value: '1 year', text: '1 year' },
                        { value: '5 years', text: '5 years' },
                        { value: '10 years', text: '10 years' },
                        { value: '15 years', text: '15 years' },
                        { value: '20 years', text: '20 years' },
                        { value: '25 years', text: '25 years' },
                        { value: '30 years', text: '30 years' }
                      ]}
                      error={errors.selectLoanPeriod?.message}
                      label="Amortized over"
                      placeholder="Select period"
                      className="finance-form__input"
                      {...field}
                    />
                  )}
                />
                <Controller
                  control={control}
                  rules={{
                    validate: validateInputPercentage
                  }}
                  name="maxDiscountPoints"
                  render={({ field: { value, onChange, onBlur }, formState: { errors } }) => (
                    <InputPercentage
                      label="Max discount points"
                      placeholder="Enter percentage"
                      value={value}
                      error={errors.maxDiscountPoints?.message}
                      className="finance-form__input"
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                />
              </div>
            </div>
          )
      ) : null}

      {paymentMethod && <Button className="finance-form__submit-btn">Next</Button>}
    </StyledBuyPFormrocessPaymentMethod>
  );
};

export default BuyProcessFormFinancials;

const StyledBuyPFormrocessPaymentMethod = styled.form`
  display: flex;
  flex-direction: column;

  .question-circle {
    fill: red;
  }

  .input,
  .dropdown,
  .finance-form__radio-group {
    margin: 0 0 12px 0;
    width: 100%;
  }

  .finance-form__input-group {
    display: grid;
    grid-template-columns: repeat(3, 183px);
    grid-gap: 80px;
  }

  .finance-form__input-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 20px;
    .finance-form__input {
      width: 100%;
    }
    .dropdown__button {
      height: 32px;
    }
  }

  .finance-form__radio-group {
    & > .label {
      font-size: 10px;
      margin-bottom: 12px;
    }
    .radiobutton {
      margin-right: 20px;
    }
  }

  .double-input label > .trigger {
    margin-right: -40px;
  }

  hr {
    border-style: dashed;
    border-color: #c4c4c4;
    margin: 20px 0;
  }

  .trigger {
    float: right;
    z-index: 10;
    circle {
      stroke: ${props => props.theme.colors.primary};
    }
  }

  .finance-form__submit-btn {
    width: 112px;
    margin: 8px 0 0 auto;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .finance-form__input-group {
      grid-template-columns: 1fr;
      grid-gap: 24px;
    }
    .finance-form__input-container {
      grid-template-columns: 1fr;
      grid-gap: 12px 0;
    }
  }
`;
