import React from 'react';
import dayjs from 'dayjs';

import styled from '@emotion/styled';
import { Button, Checkbox, Icon } from 'components';

interface DocumentsTableItemProps extends React.HTMLAttributes<HTMLTableRowElement> {
  offerCount: number;
  data: WorkspaceDocument;
  selected: boolean;
  onShareClick: (data: WorkspaceDocument) => void;
  onDeleteClick: (data: WorkspaceDocument) => void;
  onSelectChange: (event: React.ChangeEvent<HTMLInputElement>, documentId: number) => void;
  onEditClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onExecuteClick?: (data: WorkspaceDocument) => void;
}
const DocumentsTableItem: React.FC<DocumentsTableItemProps> = ({
  offerCount=0,
  data,
  selected,
  onShareClick,
  onDeleteClick,
  onSelectChange,
  onEditClick,
  onExecuteClick,
  ...props
}) => {
  const isPdf = data.name.slice(-3) === 'pdf';
  const isOffer = data.docname==='Offer' ? "document-row is-offer" : "document-row";
  return (
    <StyledTableRow className={isOffer} {...props}>
      <td className="document-row__type">
        <Checkbox
          checked={selected}
          onChange={e => onSelectChange(e, data.id)}
          label={(data.docname || data.category || '-') + (data.docname === 'Offer' ? " " + (offerCount) : "")}
        />
      </td>
      <td className="document-row__name">
        <a href={data.link} download target="_blank" rel="noreferrer">
          {data.name} {data.draft && <span className="draft-pill">Draft</span>}
        </a>   
      </td>
      <td className="document-row__signed">
        {!data.isExternal && (data.countSignatures.signed + data.countSignatures.not_signed)>0 && (data.countSignatures.signed != (data.countSignatures.signed+data.countSignatures.not_signed)) && (  
          <span className="signed-tab">SIGNED {data.countSignatures.signed}/{data.countSignatures.signed+data.countSignatures.not_signed}</span>
        )}
        {!data.isExternal && (data.countSignatures.signed + data.countSignatures.not_signed)>0 && (data.countSignatures.signed == (data.countSignatures.signed+data.countSignatures.not_signed)) && (  
          <span className="signed-tab is-signed">SIGNED {data.countSignatures.signed}/{data.countSignatures.signed+data.countSignatures.not_signed}</span>
        )}
      </td>
      <td className="document-row__date">{dayjs(data.createdOn).format('MM/DD/YYYY')}</td>
      <td className="document-row__menu">
        {!data.isExternal && (
          <div className="menu-buttons">
            
            {['Offer', 'Listing Agreement'].includes(data.docname) && data.buyProcessId && (
              <Button className="edit-button" simple onClick={onEditClick}>
                Edit
              </Button>
            )
            }
            {['Offer'].includes(data.docname) && data.documentStatus == 1 && (
              <Button className="execute-button" simple onClick={() => onExecuteClick(data)}>
                Execute
              </Button>
            )
            }
            {data.needSign && data.viewId && (
              <a
                href={`/pdf-editor/sign?view=${data.viewId}&id=${data.id}`}
                title="Needs signature"
                target="_blank"
                rel="noreferrer">
                <Icon name="sign" />
              </a>
            )}
            <Button simple title="Share" onClick={() => onShareClick(data)}>
              <Icon name="upload" />
            </Button>
            {isPdf && (
              <a href={`/pdf-editor?id=${data.id}`} target="_blank" rel="noreferrer">
                <Icon name="pen" />
              </a>
            )}
            <Button simple title="Delete" onClick={() => onDeleteClick(data)}>
              <Icon name="trash" />
            </Button>
          </div>
        )}
        {data.isExternal && (
          <Button simple title="Share" onClick={() => onShareClick(data)}>
              <Icon name="upload" />
            </Button>
        )}
      </td>
    </StyledTableRow>
  );
};

export default DocumentsTableItem;

const StyledTableRow = styled.tr`
  height: 16px;

  td {
    padding: 8px 0;
  }

  .signed-tab{
    color: #8b8b8b;
    font-weight: 500;
    display: inline-block;
    font-size: 10px;    
    width: 64px;
    text-align: center;
  }

  .is-signed{color: #62B120;font-weight:bold;}

  .draft-pill {
    color: ${props => props.theme.colors.red};
    background: ${props => props.theme.colors.seashell};
    padding: 0 4px;
    border-radius: ${props => props.theme.misc.borderRadius};
  }

  .document-row__name {
    a {
      text-decoration: none;
      color: unset;
    }

    .checkbox {
      font-weight: 500;
    }
  }

  .document-row__type {
    padding-left: 24px;
    .checkbox.disabled {
      label {
        color: #000;
      }
    }
  }

  .document-row__type,
  .document-row__date {
    color: ${props => props.theme.colors.grayDark};
  }

  .document-row__menu {
    width: 16px;
    text-align: right;
    .menu-buttons {
      display: flex;
      justify-content: flex-end;
    }

    .button,
    a {
      fill: ${props => props.theme.colors.graphite};
      margin: 0 8px;
    }
    a {
      display: flex;
    }

    .edit-button,.execute-button {
      color: ${props => props.theme.colors.red};
      white-space: nowrap;
      .icon {
        vertical-align: middle;
      }
    }
  }
`;